<template>
<el-container>

<el-header  style="height: 14rem;">
<li> <p>雨雪:{{NodeSnowSensorWaterCur}},日照:{{NodeSnowSensorSunCur}} 风力:{{NodeSnowSensorWindCur}},温度:{{NodeSnowSensorTemperatureCur}},洁净:{{NodeSnowSensorCleanCur}}</p></li>


 <h3> {{projectName}}-{{psName}}-{{devName}} (唯一标识:{{deviceSn}}) </h3>
<h3 align="center"><p> {{SystemDateCur}}节点ID:{{NodeMessageNodeId}}({{NodeMessageNodeMac}}),电源电压:{{NodeBatVlotFlag}}V </p></h3>
<h3 align="center"><p>电流:{{NodeCurrentFlag}},
状态:<font color="red">{{NodeAutoRunState}}</font>,
限位器:<font color="red">{{NodeSwitchLimitState}}</font>,
自动:<font color="red">{{NodeAutoRunCount}}({{NodeAutoRunNum}})</font>,
时间:<font color="red">{{NodeAutoRunTime}}({{NodeAutoRunTimeAll}})</font>故障:{{NodeFaultFlag}}</p></h3>
<h3>钢丝松弛度:30%，牵引轮:正常,光伏阻力：正常；运行高温：正常;电机状态：正常</h3>
<h3>{{NodeRunLogFlag}}</h3>
</el-header>

<el-main>

<p>
<el-button type="primary" @click="MkwheelsTest(1,'MAHU_CMD_MOTO_LOCKER','on')">正向</el-button>
<el-button type="primary" @click="MkwheelsTest(1,'MAHU_CMD_MOTO_LOCKER','off')">停止</el-button>
<el-button type="primary" @click="MkwheelsTest(1,'MAHU_CMD_MOTO_LOCKER','neg')">反向</el-button>
<el-button type="primary" @click="MAHU_LIGHT_AUTO()"   :disabled=true>自动</el-button>

</p>
<div   v-if="LocalConfigButtonDisplayFlag"   >
  <div  style="margin: 2rem 0;font-weight: 700" @click="handleSetParams"> {{showParams? '关闭参数设置':'参数设置' }}  </div>

<p>
<table align="center" v-show="showParams">
<tr>
<td >正.平均电流:</td>
<td >{{NodeCurrentAvgFlag}}</td>
<td >反.平均电流:</td>
<td >{{NodeCurrentNegAvgFlag}}</td>
</tr>
<tr>
<td>堵转电流:</td>
<td width="180px"><el-input    @input='StopCurrentOveInputChange'  autosize v-model="MotoStopCurrentOver" placeholder="0" class="elInp"></el-input></td>
<td>堵转时间:</td>
<td><el-input autosize v-model="MotoStopTimeOver" placeholder="0" class="elInp"></el-input></td>

</tr>

 <tr>
<td>正.过载电流:</td>
<td width="180px"><el-input  @input='FaultCurrentOveInputChange'  autosize v-model="MotoFaultCurrentOver" placeholder="0" class="elInp"></el-input></td>
<td>过载时间:</td>
<td><el-input autosize v-model="MotoFaultTimeOver" placeholder="0" class="elInp"></el-input></td>
</tr>

<tr>
<td>反.过载电流:</td>
<td  width="180px" ><el-input  @input='FaultNegCurrentOveInputChange'  autosize v-model="MotoFaultNegCurrentOver" placeholder="过载距离(时间)" class="elInp"></el-input></td>
<td>单趟(分钟):</td>
<td><el-input autosize v-model="MotoAutoRunCycleTime" placeholder="过载距离(时间)" class="elInp"></el-input></td>

 </tr>



 <tr>
<td>限位开关停机</td>
<td width="140px"><el-input autosize v-model="MotoStopSwitchStateConfig" placeholder="过载时间" class="elInp"></el-input></td>

<td>自动次数</td>
<td width="140px"><el-input autosize v-model="MotoAutoRunCount" placeholder="自动运行次数" class="elInp"></el-input></td>
</tr>
<tr>
<td><el-button type="primary" @click="MkwheelsConfig(0)">设置参数</el-button></td>
<td>  <el-button type="primary" @click="MkwheelsDrawTest(0XFF00)">清除配置</el-button></td>
<td><el-button type="primary" @click="MkwheelsDrawTest()">清除图像</el-button></td>
<td><input type="checkbox" style="margin-left: 1rem"  @click="checkAll" /></td>
</tr>
</table>
</p>







</div>

 <li>   <div id="myEcharts" :style="{ width: '100%', height: '300px' }" ></div></li>


</el-main>


</el-container>






</template>

<script>
import * as MahuConfig from "../js/mahuconfig.js"
import * as THREE from 'three'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import 'element-plus/dist/index.css'
import ElementPlus from 'element-plus'
import  * as  echarts from 'echarts'
import { ref,reactive,defineComponent,  getCurrentInstance,onMounted,onUnmounted  } from 'vue'



export default {
    name:'ClearRobot',

    data(){
        return{
          showParams: false,
          vuechartdata:{
    title: {
        text: "Traffic222 Sources",
        left: "center"
    },
    xAxis:{
        type:'category'
    },
    yAxis:{
        type:'value'
    },
    series:[{
        type:'line',
        data:[100,200,20,500,600]
    }]
},

background:"url(" + ("./img/ClearRobot02.jpg") + ")",
        TabBatShowActive:0,
backgroundColor:'dark',
leftArrow:true,
rightArrow:true,
border:true,
tabBatShow:true,
          NodeMessageGroupId:1,
      //    NodeMessageNodeId:2,
            a:"test",
          CanvasWidth:1000,
          CanvasHeight:240,
          CamerCanvasGraph:null,
          CamerCanvas:null,
          CamerCanvasImg:null,
        //  GroupNodeIdBuf:[0,0,0,0],
          canvasdisplay:"display:none"
        }
    },
    created(){
        console.log('zi组件在created调用了')

    },
    methods:{
    handleSetParams(){
      this.showParams = !this.showParams
    },
    onClickLeft(e){ console.log(" onClickLeft "+e.String());},
    onClickCenter(e){console.log(" onClickCenter "+e.String());},
     onClickRight(e){console.log(" onClickRight "+e.String());},

        test(){
            console.log(this.prop1,'prop1')
        },
        getVideo(){
      //  this.yingcang=true;
        let constraints={
        video:{width:240,height:240},
        };
        let _this=this;
        let promise=navigator.mediaDevices.getUserMedia(constraints);
        promise.then( function (MediaStream)
          {
            video.srcObject=MediaStream;
            _this.MediaStreamTrack=typeof MediaStream.stop=== 'function'?MediaStream:MediaStream.getTracks()[0];
            video.play();
          }
        ).catch( function (PermissionDeniedError){
          console.log(PermissionDeniedError)
        })

        },
        ImgLoadOver(){
          console.log("imgname load over :" );
          if(this.CamerCanvasGraph != null  )
           this.CamerCanvasGraph.drawImage(this.CamerCanvasImg,0,0,this.CanvasWidth,this.CanvasHeight)
         else{
           this.CamerCanvas =   document.getElementById("NodeMessageCanvas")
           console.log(" CamerCanvas 222",this.CamerCanvas)
           this.CamerCanvasGraph=this.CamerCanvas.getContext('2d');
           this.CamerCanvasGraph.drawImage(this.CamerCanvasImg,0,0,this.CanvasWidth,this.CanvasHeight)

         }

        }

    },
    mounted(){
        console.log('zi组件在mounted调用了')
        if(this.CamerCanvasImg==null)        {
        this.CamerCanvasImg=new Image();
          this.CamerCanvasImg.src="./img/clearrobot.jpg";
        //  console.log("imgname:"+imgName+" "+CamerCanvasGraph)
        this.CamerCanvasImg.onload=this.ImgLoadOver;
        }


    },
    beforeUnmount(){
        console.log('zi组件准备销毁了')
    },
    unmounted(){
        console.log('zi组件销毁了')
    },
    setup(props, context){
    //var NodeSwitchLimitState=ref("停止");
    var NodeStopFlag=ref("到顶");
    var NodeFaultFlag=ref("障碍");
    var NodeSwitchLimitCountFlag=ref(0);
  const SystemDateCur=ref("0");
 const    NodeMessageNodeId=ref("99");
 const    deviceSn=ref("0000");
 const    NodeMessageNodeMac=ref("B1");
 const    NodeTemporValueFlag=ref("30");
 const    NodeWater=ref("20");
 const    NodeBatVlotFlag=ref("24.6");
 const    NodeCurrentFlag=ref("0");
 const    NodeCurrentNegFlag=ref("0");
 const   projectName=ref("项目")
 const   psName=ref("电站")
 const   devName=ref("设备")
const  NodeAutoRunState=ref("0");
const NodeSwitchLimitState=ref("0")
const  NodeAutoRunDir=ref("0");
const   NodeAutoRunCount=ref("0");
const  NodeAutoRunNum=ref("0");
const  NodeAutoRunTime=ref("0");
const  NodeAutoRunTimeAll=ref("0");

const    BackImage=ref("./img/ClearRobot02.jpg");// //ref('{backgroundImage: "url(" + ("./img/ClearRobot02.jpg") + ")"}')
 const    NodeCurrentAvgFlag=ref(0);
 const    NodeCurrentNegAvgFlag=ref(0);

 const   NodeSnowSensorWaterCur=ref("20");
 const   NodeSnowSensorSunCur=ref("30");
 const   NodeSnowSensorWindCur=ref("50");
 const   NodeSnowSensorTemperatureCur=ref("30");
 const   NodeSnowSensorCleanCur=ref("10");

 const    NodeBatCap=ref("50%");
 const    NodeRoadDistOk=ref("500");
 const    NodeRoadAutoFlag =ref("停止")
 const    NodeRoadDistFree=ref("200");
  const    NodeRoadDistForward=ref("300");
   const    NodeRoadDistBack=ref("500");

   const MotoAutoRunCount=ref("2")
   const MotoAutoRunCycleTime=ref("10")
   const NodeAutoRunCountFlag=ref("0")

   const MotoStopSwitchStateConfig=ref("1");

   const MotoFaultCurrentOver=ref("0");
   const MotoFaultNegCurrentOver=ref("0");
   const MotoStopNegCurrentOver=ref("0")
   const MotoFaultTimeOver=ref("0");

   const MotoStopCurrentOver=ref("0");
   const MotoStopTimeOver=ref("0");

const items = ref([
  { message: 'Foo' },
  { message: 'Bar' },
  { message: 'tttt' }]);


    const { proxy }  = getCurrentInstance()
      let currentInstance=getCurrentInstance()
        if(currentInstance==null){
        return ;//currentInstance=this ;
    }
    const {$MkwheelsTest } =  currentInstance.appContext.config.globalProperties;
    const {$MkwheelsConfig } =  currentInstance.appContext.config.globalProperties;
    const {$WebSetNodeCurIdNo } =  currentInstance.appContext.config.globalProperties;
    const {$WebCmdByJson}=  currentInstance.appContext.config.globalProperties;
    const {$WebCmdBufByJson}=  currentInstance.appContext.config.globalProperties;
    console.log("node message setup");
      function SetAppEntry(nodeMessageGroupId,nodeMessageNodeId){

        //  NodeMessageNodeId.value=nodeMessageNodeId;
      }
      function  MAHU_CMD_WEB_LIGHT_ID(idBuf ){
      let i;
        for(i=0; i<4; i++){
        GroupNodeLists[i].mac=idBuf[i];
        console.log("mac(" +i+"):"+idBuf[i].toString(16));
        }

      }
      function MkwheelsTest(wheelNoId,cmd,dir){
      //if(dir == 0XF1)angle= MotoAutoRunCount.value;
      //  console.log("MkwheelsTest "+wheelNoId+" , "+dir+","+angle);

      //  $MkwheelsTest(wheelNoId-1,dir,angle );
      console.log(" cmd  "+cmd +" dir "+dir)
          $WebCmdByJson(cmd,dir)
      }
      function MAHU_LIGHT_AUTO(){
      let cycleTime=MotoAutoRunCycleTime.value *60;
         let addr="["+MotoAutoRunCount.value.toString()+","+cycleTime.toString()+"]";
          console.log(" auto "+addr)
          $WebCmdByJson("MAHU_LIGHT_AUTO",addr)
      }

      function MkwheelsTest2(wheelNoId,dir,angle){
        console.log("MkwheelsTest2 "+wheelNoId+" , "+dir+","+angle);

      }
      var LaserDistStartX=0;
      var LaserDistStartY=0;
      var CamerCanvasGraph=null;
      var CanvasWidth=0;
      var CanvasHeight=0;


    function  LaserDist(angleBuf,distBuf,count){
    let i=0;
    let radio =0;
    let xx,xxInt,yy,yyInt,angle,dist;
    console.log("LaserDist "+count)
    if(CamerCanvasGraph==null){
      CamerCanvasGraph=this.CamerCanvasGraph;
      CanvasWidth=this.CanvasWidth
      CanvasHeight=this.CanvasHeight
    }
    if(count==0){
    this.CamerCanvasGraph.fillStyle="#FFFFFF";
    this.CamerCanvasGraph.fillRect(0,0, CanvasWidth, CanvasHeight);
    LaserDistStartX=this.CanvasWidth/2;
    LaserDistStartY=this.CanvasHeight/2;
    this.CamerCanvasGraph.moveTo(LaserDistStartX,LaserDistStartY);

    this.CamerCanvasGraph.fillStyle="#FFFFFF";

    return ;

    }
    CamerCanvasGraph.strokeStyle="#0000ff";
    CamerCanvasGraph.beginPath();

    angle =angleBuf[i];
    angle *= Math.PI;
    angle/=18000;
    dist = distBuf[i];
    if(dist<50)dist=50;
    if(dist>1000)dist=1000;
    dist *= (CanvasWidth/2);
    dist/=1000;

     yy = dist * Math.sin(angle);
     xx = dist * Math.cos(angle);
     yy += CanvasHeight/2;
     xx += CanvasWidth/2;
     yyInt =parseInt(yy);
     xxInt =parseInt(xx);
     LaserDistStartX=xxInt;
     LaserDistStartY=yyInt;
      CamerCanvasGraph.moveTo(LaserDistStartX,LaserDistStartY);

    for(i=1; i<count; i++){
      angle =angleBuf[i];
      angle *= Math.PI;
      angle/=18000;
      dist = distBuf[i];
      if(dist<50)dist=50;
      if(dist>2000)dist=2000;
      dist *= (CanvasWidth/2);
      dist/=1000;

       yy = dist * Math.sin(angle);
       xx = dist * Math.cos(angle);
       yy += CanvasHeight/2;
       xx += CanvasWidth/2;
       yyInt =parseInt(yy);
       xxInt =parseInt(xx);
       if(xxInt<0)xxInt=0;
       if(yyInt<0)yyInt=0;
       if(xxInt>=CanvasWidth)xxInt=CanvasWidth-1;
       if(yyInt>=CanvasHeight)yyInt=CanvasHeight-1;
       //if(yy>=this.CanvasWidth)continue;
       //if(xx>=this.CanvasWidth)continue;
       CamerCanvasGraph.lineTo(xxInt,yyInt);

      }
      CamerCanvasGraph.stroke()
      LaserDistStartX=xxInt;
      LaserDistStartY=yyInt;

    }
      function NodeCanvasImg(imgName){
        var img=new Image();
          img.src=imgName;
        //  console.log("imgname:"+imgName+" "+CamerCanvasGraph)

        img.onload=function(){
          if(img.complete){

              if(CamerCanvasGraph != null  )
                CamerCanvasGraph.drawImage(img,0,0,CanvasWidth,CanvasHeight)
              else{
                CamerCanvas =   document.getElementById("NodeMessageCanvas")
                console.log(" CamerCanvas 222",CamerCanvas)
                CamerCanvasGraph=CamerCanvas.getContext('2d');
                CamerCanvasGraph.drawImage(img,0,0,CanvasWidth,CanvasHeight)
              }

          }
        }

      }

function StateMove(moveDir,moveDist){
  if(this.CamerCanvasGraph ==null)return ;
        this.CamerCanvasGraph.drawImage(this.CamerCanvasImg,0,0,this.CanvasWidth,this.CanvasHeight)
        if(moveDir==1){
          this.CamerCanvasGraph.fillStyle="#FF0000";
        }else{
          this.CamerCanvasGraph.fillStyle="#00FF00";
        }
        this.CamerCanvasGraph.fillRect(moveDist,100,20,20);
        console.log(" move "+moveDir+" dist "+moveDist)
    }
    var NodeSwitchLimitCount=0;
    var NodeBatVlot=0;
    var NodeCurrent=0;
function NodeSwitchLimitMessage(cmd,addr,writeLen,readLen,dataBuf,dataLen){
    console.log(" NodeSwitchLimitMessage "+addr);
    console.log(" MahuConfig ",MahuConfig.MAHU_BATS_CMD_SWITCH_LIMIT.toString(16));
    if(cmd==MahuConfig.MAHU_BATS_CMD_SWITCH_LIMIT ){
      NodeSwitchLimitCount++;
      NodeSwitchLimitCountFlag.value=NodeSwitchLimitCount;
    }
    if(cmd==MahuConfig.MAHU_BATS_CMD_CURRENT ){
      NodeSwitchLimitCount++;
      NodeSwitchLimitCountFlag.value=NodeSwitchLimitCount;
    }
    if(cmd==MahuConfig.MAHU_BATS_CMD_PUT_VLOATE ){
      //NodeSwitchLimitCount++;
      //NodeSwitchLimitCountFlag.value=NodeSwitchLimitCount;
      let Vcc33VlotValue=dataBuf[3];
      Vcc33VlotValue<<=8;
      Vcc33VlotValue|=dataBuf[2];

      let siVcc=dataBuf[5];
      siVcc<<=8;
      siVcc|=dataBuf[4];
      siVcc*=6;
      siVcc/=100;
      siVcc=parseInt(siVcc)
      siVcc/=10;
      NodeBatVlot=siVcc;

      NodeBatVlotFlag.value=NodeBatVlot;

      let siBatVcc=dataBuf[7];
      siBatVcc<<=8;
      siBatVcc|=dataBuf[6];
      siBatVcc*=5.5;
      siBatVcc=parseInt(siBatVcc);
      NodeCurrent = siBatVcc;
    //  NodeCurrentFlag.value=NodeCurrent

      console.log(" vcc ",Vcc33VlotValue," v24 ",siVcc ," siBatVcc ",siBatVcc)


    }
    var dateCur= new Date();
    SystemDateCur.value=dateCur.getMonth()+"_"+ dateCur.getDate()+","+dateCur.getHours()+":"+dateCur.getMinutes()  ;
}
  function NodeRoadAutoFlagSet(flag){
      if(flag==0){
        NodeRoadAutoFlag.value="停止"
      }else {
        NodeRoadAutoFlag.value="运行"
      }
  }

const ChartBufSize=100;
var Line1DataBuf=new Array(ChartBufSize);//[320, 400, 129, 132, 1201];
var Line2DataBuf=new  Array(ChartBufSize);
var Line3DataBuf=new  Array(ChartBufSize);
var LineChartXBuf=new  Uint16Array(ChartBufSize);


var LineSeriesData;
var LineChart;



function initChart() {

  LineChart = echarts.init(document.getElementById("myEcharts"), "dark");
  let i;
  for(i=0; i<ChartBufSize; i++){
      LineChartXBuf[i]=i;
  }
  for(i=0; i<ChartBufSize; i++){
      Line1DataBuf[i]=1000;
      Line2DataBuf[i]=1000;
      Line3DataBuf[i]=1100;
  }
  console.log(" line 2",Line2DataBuf)
  LineSeriesData=[  {
  itemStyle: {
  normal: {
  color: '#8cd5c2', //改变折线点的颜色
  lineStyle: {
  color: '#8cd5c2' //改变折线颜色
  }
  }
  },

      data: Line1DataBuf,
      type: "line",
      smooth: true
    },
    {
    itemStyle: {
  normal: {
  color: '#ff00c2', //改变折线点的颜色
    lineStyle: {
        color: '#ff00c2' //改变折线颜色
        }
      }
  },

       data:Line2DataBuf ,
      type: "line",
      smooth: true
    },
    {
    itemStyle: {
  normal: {
  color: '#C0C002', //改变折线点的颜色
    lineStyle: {
        color: '#C0C002' //改变折线颜色
        }
      }
  },

       data:Line3DataBuf ,
      type: "line",
      smooth: true
    }];

    // 把配置和数据放这里
    LineChart.setOption({
      xAxis: {
        type: "category",
        data: LineChartXBuf
      },
      tooltip: {
        trigger: "axis"
      },
      yAxis: {
        type: "value"
      },
      series: LineSeriesData
    });
    window.onresize = function() {
      //自适应大小
      LineChart.resize();
    };

}

const SnowSensorTimerChartBufSize=50;
var SnowSensorWaterDataBuf=new Array(SnowSensorTimerChartBufSize);//[320, 400, 129, 132, 1201];
var SnowSensorTemperatureDataBuf=new  Array(SnowSensorTimerChartBufSize);
var SnowSensorCleanDataBuf=new  Array(SnowSensorTimerChartBufSize);
var SnowSensorWindDataBuf=new  Array(SnowSensorTimerChartBufSize);
var SnowSensorSunDataBuf=new  Array(SnowSensorTimerChartBufSize);
var SnowSensorTimerChartBuf=new  Uint16Array(SnowSensorTimerChartBufSize);

var SnowSensorLineSeriesData;
var SnowSensorLineChart;

function SnowSensorInitChart() {

  SnowSensorLineChart = echarts.init(document.getElementById("SnowSensorEcharts"), "light");
  let i;
  for(i=0; i<SnowSensorTimerChartBufSize; i++){
    //  if(i&0X01){
        SnowSensorTimerChartBuf[i]=i/2;//+":30";
    //  }else{
      //  SnowSensorTimerChartBuf[i]=i/2;
    //  }
  }
  let tempInt=0;
  for(i=0; i<SnowSensorTimerChartBufSize; i++){
      SnowSensorWaterDataBuf[i]=60+parseInt(Math.random()*30);
      SnowSensorTemperatureDataBuf[i]=40+parseInt(Math.random()*20);
      SnowSensorCleanDataBuf[i]=30+parseInt(Math.random()*10);
      SnowSensorWindDataBuf[i]=20+parseInt(Math.random()*20);
      SnowSensorSunDataBuf[i]=50+parseInt(Math.random()*30);
  }
  console.log(" line 2",SnowSensorWaterDataBuf)
  SnowSensorLineSeriesData=[  {
  itemStyle: {
  normal: {
  color: '#8cd5c2', //改变折线点的颜色
  lineStyle: {
  color: '#8cd5c2' //改变折线颜色
  }
  }
  },

      data: SnowSensorWaterDataBuf,
      type: "line",
      smooth: true
    },
    {
    itemStyle: {
  normal: {
  color: '#ff00c2', //改变折线点的颜色
    lineStyle: {
        color: '#ff00c2' //改变折线颜色
        }
      }
  },

       data:SnowSensorTemperatureDataBuf ,
      type: "line",
      smooth: true
    },
    {
    itemStyle: {
  normal: {
  color: '#A02002', //改变折线点的颜色
    lineStyle: {
        color: '#C0C002' //改变折线颜色
        }
      }
  },

       data:SnowSensorCleanDataBuf ,
      type: "line",
      smooth: true
    },

    {
    itemStyle: {
  normal: {
  color: '#C0C002', //改变折线点的颜色
    lineStyle: {
        color: '#C0C002' //改变折线颜色
        }
      }
  },

       data:SnowSensorSunDataBuf ,
      type: "line",
      smooth: true
    },
    {
    itemStyle: {
  normal: {
  color: '#202002', //改变折线点的颜色
    lineStyle: {
        color: '#20C2002' //改变折线颜色
        }
      }
  },

       data:SnowSensorWindDataBuf ,
      type: "line",
      smooth: true
    }
    ];

    // 把配置和数据放这里

    SnowSensorLineChart.setOption({
    title: {
                text: '雨雪.白 温度.红 风速.蓝 日照.黄 洁净.灰'
            },
      xAxis: {
        type: "category",
        data: SnowSensorTimerChartBuf
      },
      tooltip: {
        trigger: "axis"
      },
      yAxis: {
        type: "value"
      },
      series: SnowSensorLineSeriesData
    });

    window.onresize = function() {
      //自适应大小
      SnowSensorLineChart.resize();
    };

}

var GroupNodeCount;
var GroupStateLineSeriesData;
var GroupStateLineEcharts;
var GroupStateTimerChartBufSize=60;
var GroupStateTimerChartBuf;
function GroupStateInitChart() {

GroupStateLineEcharts = echarts.init(document.getElementById("GroupStateLineEcharts"), "light");
let i,j;
GroupStateTimerChartBuf =new Uint16Array(GroupStateTimerChartBufSize);
for(i=0; i<GroupStateTimerChartBufSize; i++){
      GroupStateTimerChartBuf[i]=i;
}
GroupNodeCount=8;
GroupStateLineSeriesData =new Array(GroupNodeCount);
let nodeStateBuf ;
let nodeViewHeight=parseInt(100/GroupNodeCount);
let nodeViewCenter;
let nodeViewPosXCur,nodeViewPosYCur;

for(i=0; i<GroupNodeCount; i++){

    nodeViewPosXCur= parseInt( (Math.random()*0.6 +0.2)*GroupStateTimerChartBufSize);
    nodeStateBuf=new Array(nodeViewPosXCur);


    for(j=0; j<nodeViewPosXCur; j++ ){
      nodeViewPosYCur= parseInt( (Math.random()*0.8 +0.1)*nodeViewHeight)+nodeViewHeight*i;
      nodeStateBuf[j]=nodeViewPosYCur;
    }
    if(i&0X01){
    GroupStateLineSeriesData[i]={
    itemStyle: {
    normal: {
    color: '#8cd5c2', //改变折线点的颜色
    lineStyle: {
    color: '#8cd5c2' //改变折线颜色
    }
    }
    },

        data: nodeStateBuf,
        type: "line",
        smooth: true
      }
      }else{
      GroupStateLineSeriesData[i]={
      itemStyle: {
      normal: {
      color: '#202002', //改变折线点的颜色
      lineStyle: {
      color: '#20C2002' //改变折线颜色
      }
      }
      },

          data: nodeStateBuf,
          type: "line",
          smooth: true
        }
      }
}


GroupStateLineEcharts.setOption({
title: {
            text: '运行60分钟状态'
        },
  xAxis: {
    type: "category",
    data: GroupStateTimerChartBuf
  },
  tooltip: {
    trigger: "axis"
  },
  yAxis: {
    type: "value"
  },
  series: GroupStateLineSeriesData
});

window.onresize = function() {
  //自适应大小
  GroupStateLineEcharts.resize();
};

}
onMounted(() => {
    initChart();
  //  SnowSensorInitChart();
  //  GroupStateInitChart();
  });

  var DispFlag=0;
  function checkAll(item){
  if(DispFlag==0){
  DispFlag=1;
  this.canvasdisplay.value="";
  }else{
  DispFlag=0;
  this.canvasdisplay.value="display:none";
  }
  console.log("check ",this.canvasdisplay.value);
    //console.log("check ",item => item.isSelected)
    //return this.test.every(item => item.isSelected);
  }
  var PostFlag=0;
  var PostNegFlag=0;
  function MkwheelsDrawCurrent(motoRunState,currentValue ){
      if(currentValue<100)currentValue=100;
      if(currentValue>9000)currentValue=9000;
      console.log(" MkwheelsDrawCurrent ",currentValue)
    //  NodeCurrentFlag.value=currentValue;
      let i;
      if( motoRunState==MahuConfig.MAHU_LIGHT_ON){

      if(currentValue==9000){
      for(i=0; i<ChartBufSize; i++){
          LineSeriesData[0].data[i]=1000 ;
      }
      PostFlag=0;
      return ;
      }
      PostFlag++;
      if(PostFlag>=ChartBufSize){
        PostFlag=0;
        for(i=0; i<ChartBufSize; i++){
            LineSeriesData[0].data[i]=1000 ;
        }
      }
      LineSeriesData[0].data[PostFlag]=currentValue;
    }
    if( motoRunState==MahuConfig.MAHU_LIGHT_NEG){
    if(currentValue==9000){
    for(i=0; i<ChartBufSize; i++){
        LineSeriesData[0].data[i]=1000 ;
    }
    PostNegFlag=0;
    return ;
    }
    PostNegFlag++;
    if(PostNegFlag>=ChartBufSize){
      PostNegFlag=0;
      for(i=0; i<ChartBufSize; i++){
          LineSeriesData[1].data[i]=1000 ;
      }
    }
    LineSeriesData[1].data[PostNegFlag]=currentValue;
  }
    LineChart.setOption({ series: LineSeriesData});
  return ;
}

  function  MkwheelsDrawCurrentBuf(dir,currentValueBuf ,count){
  let i,j,currentValue;
  for(j=0; j<count; j++){
  currentValue=currentValueBuf[j];

if(currentValue<500)currentValue=500;
if(currentValue>9000)currentValue=9000;


if(dir==0){
PostFlag++;
if(PostFlag>=ChartBufSize){
  PostFlag=0;
  for(i=0; i<ChartBufSize; i++){
      LineSeriesData[0].data[i]=3000 ;
  }
}
LineSeriesData[0].data[PostFlag]=currentValue;
}
if(dir==1){
PostNegFlag++;
if(PostNegFlag>=ChartBufSize){
PostNegFlag=0;
for(i=0; i<ChartBufSize; i++){
    LineSeriesData[1].data[i]=1000 ;
}
}
LineSeriesData[1].data[PostNegFlag]=currentValue;
}
}
LineChart.setOption({ series: LineSeriesData});
return ;
}

  function MkwheelsDrawTest( ){
 let i;
  PostFlag=0;
  PostNegFlag=0;
  for(i=0; i<ChartBufSize; i++){
      LineSeriesData[0].data[i]=3000 ;
      LineSeriesData[1].data[i]=1000 ;
  }
  LineChart.setOption({ series: LineSeriesData});

  /*
      let ii =Math. random()*200;
      ii =parseInt(ii);
      ii+=100;
      //Line1DataBuf[PostFlag]=3000+ii;
      LineSeriesData[0].data[PostFlag]=3000+ii;
      PostFlag++;
      if(PostFlag>=ChartBufSize) PostFlag=0;
      //LineSeriesData[0].data=Line1DataBuf;
      LineChart.setOption({ series: LineSeriesData});
      console.log("lin ",ii,Line1DataBuf.length)
      */
  }
  function ElCheckboxGroupChekalla(){
  console.log(" ElCheckboxGroupChekalla");
  }
  function ElCheckboxGroupChekChange(value){

  }
  const isIndeterminate=ref("true")
  function ElCheckboxGroupTest(){
  console.log(" ElCheckboxGroupTestv");
  }
  function ElCheckboxGroupChange(){
  if(isIndeterminate.value==true)
  isIndeterminate.value=false
  else
  isIndeterminate.value=true

  console.log(" ElCheckboxGroupChange");
  }
  function ElCheckboxGroupTest2(){
  console.log(" ElCheckboxGroupTestv");
  }
  function ElCheckboxGroupChange2(){
  if(isIndeterminate.value==true)
  isIndeterminate.value=false
  else
  isIndeterminate.value=true

  console.log(" ElCheckboxGroupChange");
  }
function ElCheckboxGroupChekNode(checked, item)
{
  console.log(" ----ElCheckboxGroupChekNode "+checked+"---"+JSON.stringify(item))
}

  function MkwheelsConfig(configFlag){
    if(configFlag==0 ){
    //  if( this.NodeCurrentAvgFlag==0){
    //    window.alert("正向电流 配置不对");
    //    return ;
    //  }
    //  if( this.NodeCurrentNegAvgFlag==0){
    //    window.alert("反向电流 配置不对");
      //  return ;
    //  }
    }
    console.log("app",configFlag.toString(16));
    console.log("MotoFaultCurrentOver:",MotoFaultCurrentOver.value);
    console.log("MotoFaultTimeOver:",MotoFaultTimeOver.value);
    console.log("MotoFaultNegCurrentOver:",MotoFaultNegCurrentOver.value);
    console.log("MotoStopCurrentOver:",MotoStopCurrentOver.value);
    console.log("MotoStopTimeOver:",MotoStopTimeOver.value);
    console.log("MotoAutoRunCount:",MotoAutoRunCount.value);
    console.log("MotoAutoRunCycleTime:",MotoAutoRunCycleTime.value);
    let  config={"MotoFaultCurrentOver":MotoFaultCurrentOver.value,
                "MotoFaultTimeOver":MotoFaultTimeOver.value,
                "MotoFaultNegCurrentOver":MotoFaultNegCurrentOver.value,
                "MotoStopCurrentOver":MotoStopCurrentOver.value,
                "MotoStopTimeOver":MotoStopTimeOver.value,
                "MotoAutoRunCount":MotoAutoRunCount.value,
                "MotoAutoRunCycleTime":MotoAutoRunCycleTime.value
               };
               /*
    if(configFlag==0XFF00) {
      this.MotoFaultCurrentOver=0;
      this.MotoFaultTimeOver=0;
      this.MotoFaultNegCurrentOver=0;
      this.MotoStopCurrentOver=0;
      this.MotoStopTimeOver=0;
      this.MotoAutoRunCount=0;
      this.MotoAutoRunCycleTime=0;
    }

     let yunCmd ={
      deviceSn:deviceSn.value,//":"SNOW112233445566",
	 requestId:123123,//":1709867547576,
	 commandId:"asdfasd",//":"$remote_control",
	 paras:MotoStopCurrentOver,//":{"cmd ":"on"},
	 state:"on",
 command:"MotoStopCurrentSet"
     }
  //*/
    let valueBuf=new Uint16Array(4);
    valueBuf[0]=MotoStopCurrentOver.value;
      valueBuf[1]=MotoStopNegCurrentOver.value;
      if(valueBuf[1]==0){
        valueBuf[1]=valueBuf[0];
      }
        valueBuf[2]=MotoFaultCurrentOver.value;
          valueBuf[3]=MotoFaultNegCurrentOver.value;

    $WebCmdBufByJson("MotoSetStopCurrent",valueBuf,4);//MotoStopCurrentOver.value)
/*    $MkwheelsConfig(config,configFlag.toString());
    let i;
    let stopCurrent= MotoStopCurrentOver.value;
    if(stopCurrent>8500) stopCurrent=8500;
    if(stopCurrent<3000) stopCurrent=3000;
    for(i=0; i<ChartBufSize; i++){
        LineSeriesData[2].data[i]=stopCurrent;
    }
      LineChart.setOption({ series: LineSeriesData});
      */
  }
  function MkwheelsConfigGet(valueBuf){
    console.log("MkwheelsConfigGet "+valueBuf)
    if(valueBuf!=undefined  && valueBuf!="null"){
      let setValue = JSON.parse(valueBuf);
      console.log("value1:"+setValue[0]+","+setValue[1]+","+setValue[2]+","+setValue[3]);
      MotoStopCurrentOver.value=setValue[0];
      MotoStopNegCurrentOver.value=setValue[1];
      MotoFaultCurrentOver.value=setValue[2];
      MotoFaultNegCurrentOver.value=setValue[3];
    }
  }
  function MkwheelsConfigSetStopLine(stopCurrent){
  let i;
   if(stopCurrent>8500) stopCurrent=8500;
  if(stopCurrent<3000) stopCurrent=3000;
  for(i=0; i<ChartBufSize; i++){
      LineSeriesData[2].data[i]=stopCurrent;
  }
    LineChart.setOption({ series: LineSeriesData});
  }
  const cities = ['Shanghai', 'Beijing', 'Guangzhou', 'Shenzhen']

  var GroupNodeLists=[{id:1,name:'1#',mac:1234},{id:2,name:'2#',mac:324},
                      {id:3,name:'3#',mac:0},{id:4,name:'4#',mac:0},
  ];
  var GroupNodeDisabled=[false,false,false,false];
  //const GroupNodeSelect = ref([{id:1,name:'1#',mac:1234}])
  const GroupNodeSelect = ref()
  const SnowSensorViewDefault=ref(["雨雪"]);
  const SnowSensorViewList=["雨雪","日照","风力","温度","洁净"];
  const SnowSensorViewColorBuf=['#ff00c2','#00FFc2','#0000c2','#8080F2','#AA5522'];

  const SnowSensorViewChangeColorBuf=['#ff0000','#00FF00','#00FF00','#008000','#005522'];
  var SnowSensorDataBuf=[SnowSensorWaterDataBuf,SnowSensorSunDataBuf, SnowSensorWindDataBuf, SnowSensorCleanDataBuf,  SnowSensorTemperatureDataBuf];

  function SnowSensorViewGroupChekChange(value){
    console.log(" SnowSensorViewGroupChekChange  "+ value+" length "+value.length)
    let i=0,j=0;
      var option =SnowSensorLineChart.getOption();
    for(j=0; j<option.series.length; j++){
      option.series.pop();
    }

    if(value.length==0){
      //SnowSensorLineSeriesData=[];
      SnowSensorLineChart.setOption(option,true);
      return ;
    }
  //  SnowSensorLineSeriesData=[];
    for(i=0;i<value.length; i++){
      for(j=0; j<SnowSensorViewList.length; j++){
        if(value[i]==SnowSensorViewList[j])break;
      }
      if(j==SnowSensorViewList.length){
      continue;
    }
      let   lineData= {
        id:"SnowSensor"+j,
        itemStyle: {
        normal: {
            color: SnowSensorViewColorBuf[j],//'#8cd5c2', //改变折线点的颜色
            lineStyle: {   color: SnowSensorViewChangeColorBuf[j]//'#8cd5c2' //改变折线颜色
                    }
        }
        },
        data: SnowSensorDataBuf[j],
        type: "line",
        smooth: true
        };
        //SnowSensorLineSeriesData.push(lineData);
        option.series.push(lineData);
    }

      SnowSensorLineChart.setOption(option,true);
  }
  function SnowSensorStateRefresh(value){
    if(value==0){
      NodeSnowSensorWaterCur.value=0;
    }
    if(value==0XF8){
      NodeSnowSensorWaterCur.value=100;
    }
}
var MotoRunStateFlag=0;

function MotoStateRefresh2(motoRunState,switchState,curValue){
if(motoRunState==MahuConfig.MAHU_LIGHT_ON){
  NodeAutoRunState.value="前行";
  }
  if(motoRunState==MahuConfig.MAHU_LIGHT_NEG){
    NodeAutoRunState.value="返回";
    }
if(motoRunState==MahuConfig.MAHU_LIGHT_OFF){
    let posFlag="位置未知";
      if(switchState==MahuConfig.MAHU_LIGHT_ON)     posFlag="本地泊位.停";

        if(switchState==MahuConfig.MAHU_LIGHT_NEG)   posFlag="远端.停";
      if(  switchState==MahuConfig.MAHU_CURRENT_ZERO )posFlag="电机失效.停";
        if(  switchState==MahuConfig.MAHU_CURRENT_REST )posFlag="空转.停";

        NodeAutoRunState.value=posFlag ;
}

    if(motoRunState==MahuConfig.MAHU_LIGHT_OFF)
    NodeCurrentFlag.value=curValue
    let curValue2=curValue;
      if(curValue2>8999 )curValue2=8999
    if((MotoRunStateFlag==MahuConfig.MAHU_LIGHT_OFF)   &&  (motoRunState==MahuConfig.MAHU_LIGHT_ON)) {
      curValue2=9000
          }

  if((MotoRunStateFlag==MahuConfig.MAHU_LIGHT_OFF)   &&  (motoRunState==MahuConfig.MAHU_LIGHT_NEG)) {
          curValue2=9000
          }
    MkwheelsDrawCurrent(motoRunState,curValue2)
    MotoRunStateFlag=motoRunState;



    if( motoRunState==MahuConfig.MAHU_LIGHT_ON){
    if(MotoStopCurrentOver.value==0){
      MotoStopCurrentOver.value = curValue*2;
    }
    if(MotoFaultCurrentOver.value==0){
    MotoFaultCurrentOver.value = curValue*3;
    }
    }

    if(motoRunState==MahuConfig.MAHU_LIGHT_NEG){
    if(MotoStopNegCurrentOver.value==0){
      MotoStopNegCurrentOver.value =curValue*2;
    }
    if(MotoFaultNegCurrentOver.value==0){
      MotoFaultNegCurrentOver.value = curValue*3;
    }
    }
  var dateCur= new Date();
    SystemDateCur.value= dateCur.getDate()+","+dateCur.getHours()+":"+dateCur.getMinutes()  ;
}
function MotoStateRefresh(ElfDefenseMessage){
/*
String name;
int  NodeAutoRunDir;
int  NodeAutoRunState;
int  NodeAutoRunNum;
int  NodeAutoRunCount;
int  NodeAutoRunTime;
int  NodeAutoRunTimeAll;
int  NodeAutoRunNegTimeAll;
int  NodeAutoRunNegTime;


const  NodeAutoRunState=ref("0");
const  NodeAutoRunDir=ref("0");
const   NodeAutoRunCount=ref("0");
const  NodeAutoRunNum=ref("0");
const  NodeAutoRunTime=ref("0");
const  NodeAutoRunTimeAll=ref("0");

*/

console.log("ElfDefenseMessage: "+ElfDefenseMessage.toString())
console.log("MahuConfig.ONN"+ MahuConfig.MAHU_LIGHT_ONN.toString(16));
console.log("MahuConfig.NEG"+ MahuConfig.MAHU_LIGHT_NEG.toString(16));
console.log("MahuConfig.OFF"+ MahuConfig.MAHU_LIGHT_OFF.toString(16));

  if(ElfDefenseMessage.NodeAutoRunState==MahuConfig.MAHU_LIGHT_ONN){
      NodeAutoRunState.value="前行";
  }
  else if(ElfDefenseMessage.NodeAutoRunState==MahuConfig.MAHU_LIGHT_NEG){
      NodeAutoRunState.value="返回";
  }
  else if(ElfDefenseMessage.NodeAutoRunState==MahuConfig.MAHU_LIGHT_OFF){
    if(ElfDefenseMessage.NodeAutoRunDir==MahuConfig.MAHU_LIGHT_ONN)
    {  NodeAutoRunState.value="本地泊位";  }
    else if(ElfDefenseMessage.NodeAutoRunDir==MahuConfig.MAHU_LIGHT_NEG){
      NodeAutoRunState.value="远端泊位";
    }
    else
    {  NodeAutoRunState.value="位置未知";  }
  }

  NodeAutoRunTime.value =ElfDefenseMessage.NodeAutoRunTime;
  NodeAutoRunTimeAll.value =ElfDefenseMessage.NodeAutoRunTimeAll;
  NodeAutoRunCount .value =ElfDefenseMessage.NodeAutoRunCount;
  NodeAutoRunNum.value =ElfDefenseMessage.NodeAutoRunNum;

}
var NodeCheckState=[false,false,false,false];

function  GroupNodeChange(value,index){
let i=0;
    console.log("GroupNodeChange :"+JSON.stringify(value)+" id: "+index+" value "+value)
  /*  if(index==0)   {
      if(value){ //GroupNodeLists[3].mac=1;
      GroupNodeDisabled[3]=true;//
      console.log(" true");
      }
      else{// GroupNodeLists[3].mac=0;
      GroupNodeDisabled[3]=false;
            console.log("false");
      }
    }
    if(index==1) {
    if(value) GroupNodeDisabled[2]=true;//
    else      GroupNodeDisabled[2]=false;//
       }
       */
       let mac;
        if(value){
          if(NodeMessageNodeId.value==index+1)   return ;
          if(index==0)BackImage.value="./img/ClearRobot01.jpg";
          if(index==1)BackImage.value="./img/ClearRobot02.jpg";
          NodeMessageNodeId.value=index+1;
          mac=GroupNodeLists[index].mac &0XFFFF;
          NodeMessageNodeMac.value=mac.toString(16);
          $WebSetNodeCurIdNo(index);
          console.log(" WebSetNodeCurIdNo "+index)
          NodeCheckState[index]=true;
          return ;
        }
        NodeCheckState[index]=false;
        for(i=0; i<4; i++){
          if(NodeCheckState[i])break;
        }
        if(i>=4){
          NodeMessageNodeId.value="无机器";
          NodeMessageNodeMac.value=(0).toString(16);
          $WebSetNodeCurIdNo(0XFFFF);

          return ;
        }
        mac=GroupNodeLists[index].mac &0XFFFF;
        NodeMessageNodeId.value=i+1;
        if(i==0)BackImage.value="./img/ClearRobot01.jpg";
        if(i==1)BackImage.value="./img/ClearRobot02.jpg";
        NodeMessageNodeMac.value=mac.toString(16);
        $WebSetNodeCurIdNo(i);
        console.log(" WebSetNodeCurIdNo "+index)


}
function DeviceSnSet(sn){
  deviceSn.value = sn;
}

function MotoRunTime(cmd,runTime,runTimeAll){
var date = new Date();
/*
date.setTime(start);
let startTime=date.getHours()+":"+date.getMinutes()+":"+date.getSeconds();
date.setTime(stop);
let stopTime=date.getHours()+":"+date.getMinutes()+":"+date.getSeconds();
let useTime= stop-start;
useTime=parseInt(useTime/1000);

 console.log(" use :"+useTime+" start :"+startTime +" stop:"+stopTime)
 */
 //runTime>>=10;
 //runTimeAll>>=10;
 NodeAutoRunTime.value =runTime;
 NodeAutoRunTimeAll.value=runTimeAll;
}
const LocalConfigButtonDisplayFlag=ref(false)
function ConfigButtonSet(dispFlag){
  if(dispFlag==true){
LocalConfigButtonDisplayFlag.value = true;
  }else{
    LocalConfigButtonDisplayFlag.value=false ;
  }
}
function NodeSnowSensorTemperatureCurSet(value){
    NodeSnowSensorTemperatureCur.value=value;
}

var FaultCurrentOveInputChangFlag=false;
var FaultNegCurrentOveInputChangFlag=false;



function StopCurrentOveInputChange(v){
let vv=parseInt(v);
  console.log(" stop curr "+vv)
  if(FaultCurrentOveInputChangFlag==false){
      MotoFaultCurrentOver.value=vv+200;
  }
  if(FaultNegCurrentOveInputChangFlag==false){
      MotoFaultNegCurrentOver.value=vv+400;
  }
}

function FaultCurrentOveInputChange(v){
  FaultCurrentOveInputChangFlag=true;
  console.log(" FaultCurrentOveInputChange curr "+v)
}


function FaultNegCurrentOveInputChange(v){
FaultNegCurrentOveInputChangFlag=true;
  console.log(" FaultNegCurrentOveInputChange curr "+v)
}
const NodeRunLogFlag=ref("当前运行记录");
function NodeRunLog(log){
  NodeRunLogFlag.value=":"+log;
}
      return {
      NodeTemporValueFlag,
      NodeWater,
      NodeBatVlotFlag,
      NodeBatVlot,
      NodeCurrentFlag,
      NodeCurrentNegFlag,
      NodeCurrentAvgFlag,
      NodeCurrentNegAvgFlag,
      NodeCurrent,
      NodeBatCap,
      NodeRoadDistOk,
      NodeRoadAutoFlag,
      NodeRoadAutoFlagSet,
      NodeRoadDistFree,
      NodeRoadDistForward,
      NodeRoadDistBack,
      MkwheelsTest,
      MkwheelsTest2,
    //  WebSetNodeCurIdNo,
      MAHU_CMD_WEB_LIGHT_ID,
      SetAppEntry,
      StateMove,
      NodeCanvasImg,
      NodeStopFlag,
      NodeFaultFlag,
      NodeMessageNodeId,
      NodeMessageNodeMac,

      MotoStopSwitchStateConfig,
  //    NodeSwitchLimitState,
      NodeSwitchLimitMessage,
      NodeSwitchLimitCount,
      NodeSwitchLimitCountFlag,
      SystemDateCur,
      initChart,
      SnowSensorInitChart,
      checkAll,
      DispFlag,
      MotoFaultCurrentOver,
      MotoFaultTimeOver,
      MotoFaultNegCurrentOver,
      MotoStopCurrentOver,
      MotoStopTimeOver,
      MotoAutoRunCount,
      MotoAutoRunCycleTime,
      NodeAutoRunCountFlag,
      MkwheelsConfig,
      MkwheelsDrawTest,
      MkwheelsDrawCurrent,
      MkwheelsDrawCurrentBuf,
      MkwheelsConfigSetStopLine,

       NodeSnowSensorWaterCur,
       NodeSnowSensorSunCur,
         NodeSnowSensorWindCur,
         NodeSnowSensorTemperatureCur,
         NodeSnowSensorTemperatureCurSet,
         NodeSnowSensorCleanCur,
         ElCheckboxGroupChekalla,
         items,
         ElCheckboxGroupTest,
         ElCheckboxGroupChange,
         ElCheckboxGroupTest2,
         ElCheckboxGroupChange2,
         isIndeterminate,
         ElCheckboxGroupChekNode,
         ElCheckboxGroupChekChange,
         cities,


         SnowSensorLineSeriesData,
         SnowSensorLineChart,
         SnowSensorViewList,
         SnowSensorViewColorBuf,
         SnowSensorViewChangeColorBuf,
         SnowSensorDataBuf,
         SnowSensorViewDefault,
         SnowSensorViewGroupChekChange,
         SnowSensorStateRefresh,

         GroupNodeCount,
         GroupStateLineSeriesData,
         GroupStateLineEcharts,
         GroupNodeLists,
         GroupNodeSelect,
         GroupNodeChange,
         GroupNodeDisabled,

          NodeAutoRunState,
          NodeSwitchLimitState,
          NodeAutoRunDir,
           NodeAutoRunCount,
          NodeAutoRunNum,
           NodeAutoRunTime,
           NodeAutoRunTimeAll,

           MotoStateRefresh,
           BackImage,
           deviceSn,
           DeviceSnSet ,
           MotoStateRefresh2,
           MkwheelsConfigGet,
           MAHU_LIGHT_AUTO,
           MotoRunTime,

           LocalConfigButtonDisplayFlag,
           ConfigButtonSet,
           projectName,
           psName,
           devName,
           StopCurrentOveInputChange,
           FaultCurrentOveInputChangFlag,
           FaultCurrentOveInputChange,
           FaultNegCurrentOveInputChangFlag,
           FaultNegCurrentOveInputChange,
           NodeRunLogFlag,
           NodeRunLog

      }
    }
}
</script>

<style  scoped>

.elInp{
  width: 5rem;
}
td{
  font-size: 0.8rem;
}
li{
  list-style: none;
}



</style>
